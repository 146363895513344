































import Vue, { PropType } from 'vue';
import { VPopover } from 'v-tooltip';
import { Sprint } from '@/types/Sprint';
import FormEditSprintName from '../forms/form-edit-sprint-name.vue';

export default Vue.extend({
  components: {
    VPopover
  },
  props: {
    sprint: {
      type: Object as PropType<Sprint>
    }
  },
  methods: {
    toggleUpdateModal() {
      this.$modal.show(
        FormEditSprintName,
        { value: this.sprint },
        { scrollable: true, maxWidth: 440, adaptive: true }
      );
    }
  }
});
