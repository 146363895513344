












































import { Sprint } from '@/types/Sprint';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<Sprint | Partial<Sprint>>,
      default(): Partial<Sprint> {
        return {
          name: ''
        };
      }
    }
  },
  data() {
    return {
      name: ''
    };
  },
  computed: {
    changes(): Partial<Sprint> {
      const changes: Partial<Sprint> = {};
      if (this.name !== this.value.name) changes.name = this.name;
      return changes;
    },
    changed(): boolean {
      return Object.keys(this.changes).length > 0;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value: Sprint) {
        this.setForm(value);
      }
    }
  },
  methods: {
    setForm({ name }: Sprint) {
      this.name = name;
    },
    async save() {
      if (this.changed) {
        const changes = { ...this.changes, id: this.value.id };
        await this.$store.dispatch('sprint/updateSprint', changes);
        const title = `${this.$t('notifications.update_success', [
          this.$t('sprint.name')
        ])}`;
        this.$notify({
          title,
          type: 'success',
          group: 'app-noti'
        });
        this.onCancel();
      }
    },
    onCancel() {
      this.$emit('close');
    }
  }
});
