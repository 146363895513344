<template>
  <div>
    <div
      class="fixed shadow rounded-lg bg-primary-50 flex justify-between items-center px-5 py-3 text-primary backlog--alert"
      style="width:640px; bottom:40px; height:48px"
    >
      <span>{{ `${getSelectedTask.length} tasks selected` }}</span>

      <base-dropdown
        class="text-gray-20 backlog--dropdown"
        position="right-end"
        :is-show-icon-close="false"
        @hide="searchText = ''"
      >
        <slot slot="toggle">
          <button class="flex text-primary">
            <span> Move to</span>
            <icon-alert-right />
          </button>
        </slot>
        <div slot="header" class="flex items-center pl-3">
          <span>Move to</span>
        </div>
        <base-dropdown-item keep :hoverable="false">
          <base-input-text
            v-model="searchText"
            placeholder="Search"
            class="bg-gray-main"
            inner-class="base-input--gray"
            :icon-right="searchText ? 'IconClose' : null"
            @icon-right-click="searchText = ''"
          />
        </base-dropdown-item>
        <base-dropdown-item
          v-for="sprint in filterSprints"
          :key="sprint.id"
          @click="moveTask(sprint)"
        >
          {{ sprint.name }}
        </base-dropdown-item>
      </base-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import IconAlertRight from '@/components/icons/IconAlertRight.vue';
import apis from '@/services/apis';
export default {
  data: () => ({ searchText: '' }),
  components: {
    IconAlertRight
  },
  filters: {
    filter: function(value) {
      if (!value.length) return [];
      return value.filter(
        e => e.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1
      );
    }
  },
  computed: {
    ...mapGetters({
      sprints: 'sprint/getSprints',
      getSelectedTask: 'sprint/getSelectedTask',
      boardId: 'boards/getActiveBoardId'
    }),
    filterSprints() {
      if (!this.sprints.length) return [];
      return this.sprints.filter(
        e => e.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1
      );
    }
  },
  methods: {
    ...mapMutations({
      setShowSelectTask: 'sprint/SET_SHOW_SELECT_TASK',
      resetSelectedTask: 'sprint/RESET_SELECTED_TASK'
    }),
    ...mapActions({
      fetchSprints: 'sprint/fetchSprints',
      fetchTasks: 'tasks/fetchTasks'
    }),
    async moveTask(sprint) {
      const sprintID = sprint.id;
      const taskIDs = this.getSelectedTask.map(e => e.id);
      const params = {
        sprintID,
        taskIDs
      };
      await apis
        .patch(`/boards/${this.boardId}/tasks`, params)
        .then(() => {
          this.setShowSelectTask(false);
          this.resetSelectedTask();

          // fetch sprints & tasks
          const group = 'app-noti';
          this.fetchSprints();
          this.fetchTasks({ boardID: this.boardId });
          this.$notify({
            text: 'Move task success',
            type: 'success',
            group
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style scoped>
.backlog--alert {
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
<style>
.backlog--dropdown .hoverable {
  @apply px-2 mx-6;
}
.backlog--dropdown .base-dropdown__item:not(.hoverable, .sticky) {
  @apply mx-2;
}

.backlog--dropdown .base-dropdown__item.hoverable:not(.active):hover {
  @apply rounded-lg;
}
</style>
