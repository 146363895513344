<template>
  <div>
    <header-task-filters
      :placeholder="$t('agile.backlog.search.task')"
      bottom-border
      class="agile-content__container"
      is-show-select-task
      @loading-task="loadingTask"
    />
    <div class="agile-content__container">
      <list-item-sprint
        v-for="sprint in sprints"
        :key="sprint.id"
        :sprint="sprint"
        :freeze="isTaskDragging"
        class="py-3"
        @sprint-add="handleCreateSprint"
        @sprint-start="handleSprintStart"
        @sprint-delete="promptDeleteSprint"
        @task-add="createTask"
        @drag-change="handleTaskDrag"
        @drag-task="isTaskDragging = $event"
      />
      <router-view />
    </div>
    <board-agile-backlog-alert v-if="isShowSelect && getSelectedTask.length" />
    <modal-sprint-start
      v-if="toStartSprint"
      :name="toStartSprint.name"
      @start="handleSprintStart"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { tasksApi } from '@/services/apis';
import BoardUtils from '@/mixins/BoardUtils';
import MembersVisible from '@/mixins/MembersVisible';

import ListItemSprint from '@/components/list/list-item-sprint';
import HeaderTaskFilters from '@/components/header/header-task-filters.vue';
import ConfirmModal from '@/components/board/board-dialog-confirm.vue';
import ModalSprintStart from '@/components/modal/modal-sprint-start.vue';
import RecalculatePosition from '@/mixins/RecalculatePosition';
import BoardAgileBacklogAlert from '@/components/board/board-agile-backlog-alert.vue';

export default {
  mixins: [BoardUtils, MembersVisible, RecalculatePosition],
  components: {
    ListItemSprint,
    HeaderTaskFilters,
    ModalSprintStart,
    BoardAgileBacklogAlert
  },
  data() {
    return {
      isTaskDragging: false
    };
  },
  computed: {
    ...mapGetters('sprint', {
      sprints: 'getSprints',
      toStartSprint: 'getReadyToStartSprint',
      isShowSelect: 'getShowSelect',
      getSelectedTask: 'getSelectedTask'
    }),
    ...mapGetters('members', ['getUserMember'])
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.SET_FILTERS({ sprintId: null });
      vm.SET_SHOW_SELECT_TASK(false);
      vm.RESET_SELECTED_TASK();
    });
  },

  methods: {
    ...mapMutations('tasks', ['SET_FILTERS', 'ADD_TASK', 'UPDATE_TASKS']),
    ...mapMutations('sprint', ['SET_SHOW_SELECT_TASK', 'RESET_SELECTED_TASK']),
    ...mapActions('sprint', ['createSprint', 'deleteSprint', 'startSprint']),
    ...mapActions('tasks', ['setTask']),
    splitToArray(routeValue) {
      return routeValue ? routeValue.split(',').map(e => parseInt(e)) : [];
    },
    createTask(task) {
      const { assigneeIDs, tagIDs } = this.$route.query;
      const fetchTasksTags = this.splitToArray(tagIDs);
      const fetchTasksAssign = this.splitToArray(assigneeIDs);
      const newTaskMeta = {
        boardID: this.boardId,
        assigneeIDs: fetchTasksAssign,
        tagIDs: fetchTasksTags
      };
      tasksApi.create({ ...task, ...newTaskMeta }).then(res => {
        this.ADD_TASK(res.data);
      });
    },
    handleCreateSprint() {
      this.createSprint({ boardID: this.boardId });
    },
    promptDeleteSprint(sprintId) {
      const sprint = this.sprints.find(s => s.id === sprintId);
      this.$modal.show(
        ConfirmModal,
        {
          title: this.$t('agile.delete.title'),
          description: `${this.$t('agile.delete.description')} <strong>${
            sprint.name
          }</strong> ${this.$t('agile.delete.description1')} <strong>${
            sprint.name
          }</strong> ${this.$t('agile.delete.description2')}`,
          button: this.$t('agile.delete.button'),
          onConfirm: () => {
            this.deleteSprint(sprintId);
          }
        },
        {
          height: 'auto',
          maxWidth: 440,
          adaptive: true
        }
      );
    },
    handleSprintStart(data) {
      const startSprintRequest = { id: this.toStartSprint.id, data };
      this.startSprint(startSprintRequest);
      this.$modal.toggle('sprint-start');
      if (this.getUserMember.role === 100) {
        this.$router.push({
          name: 'Board-Sprint'
        });
      }
    },
    loadingTask(e) {
      this.loading = e;
    },
    handleTaskDrag(task) {
      const sprint = this.sprints.find(sprint => sprint.id === task.sprintID);
      const shouldRecalculatePostion = this.shouldRecalculatePostion(
        task.position,
        sprint.tasks
      );
      if (shouldRecalculatePostion) {
        const tasks = this.recalculateItemsPosition(sprint.tasks, task);
        this.UPDATE_TASKS(tasks);
      }
      if (!task.event.added) {
        delete task.sprintID;
      }
      delete task.event;
      this.setTask(task);
    }
  }
};
</script>

<style scoped>
.height--spinner {
  height: 500px;
}
</style>
