<template>
  <base-collapse :key="sprint.id" :title="sprint.name">
    <template #collapser="{ isCollapsed, toggle }">
      <collapse-toggle
        class="hover:bg-light-gray"
        :class="{ 'mb-3': !isCollapsed }"
        :active="isCollapsed"
        @click="toggle"
      >
        <div class="flex items-center justify-between flex-wrap">
          <div class="font-semibold">
            <div>
              {{ sprint.name }}
              <small class="text-gray-caption">
                {{ $tc('counts.task', sprint.tasks.length) }}
              </small>
            </div>
            <div class="flex -mx-2">
              <info-work-duration
                v-if="sprint.isActive"
                :start="sprint.startAt"
                :end="sprint.endAt"
                class="sprint__detail"
              />
            </div>
          </div>
          <div class="flex items-center">
            <div
              class="
                bg-gray-200
                rounded-full
                flex
                items-center
                mr-2
                text-sm
                px-3
                py-2
              "
            >
              <base-icon icon="star" size="14" class="" />
              <span>{{ $tc('counts.story_point', $n(sprint.points)) }}</span>
            </div>
            <div
              v-if="isUserAdmin && sprint.allowStart"
              class="sprint__actions"
            >
              <base-button wide="board" @click.stop="toggleStart">
                <span class="capitalize">
                  {{ $t('sprint.start') }}
                </span>
              </base-button>
            </div>

            <div v-if="isUserAdmin && sprint.isBacklog" class="sprint__actions">
              <base-button
                wide="board"
                @click.stop="$emit('sprint-add', $event)"
              >
                <span class="capitalize">
                  {{ $t('sprint.add') }}
                </span>
              </base-button>
            </div>
            <div v-if="!isUserViewer && !isEmptyTasks" class="sprint__actions">
              <base-button
                color="light-outline"
                wide="board"
                :data-testid="`create-task-${sprint.name}`"
                @click.stop="
                  onCreateTaskButtonClick({
                    toggle,
                    isCollapsed,
                    event: $event
                  })
                "
              >
                <span class="capitalize">{{ $t('task.create') }}</span>
              </base-button>
            </div>
            <div
              v-if="isUserAdmin && !sprint.isBacklog"
              class="sprint__actions mr-2"
            >
              <list-item-sprint-menu
                :sprint="sprint"
                @click.native.stop
                @delete="$emit('sprint-delete', sprint.id)"
              />
            </div>
          </div>
        </div>
      </collapse-toggle>
    </template>
    <div ref="freezeHeight" class="ml-12" :style="{ height }">
      <list-tasks-draggable
        v-if="!loading"
        v-model="tasks"
        :freeze="freeze"
        @change="dragChange"
        @click="openTaskDetail"
        @dragstart="dragstart"
        @dragend="dragend"
      />
      <div v-if="!freeze" class="pt-4">
        <wrapper-observe-visibility>
          <template #default="{ visible }">
            <div v-if="addTaskActive" class="flex">
              <paragraph-editable
                ref="insertTaskName"
                v-model="addTaskName"
                class="break-all flex-1"
                autofocus
                :enter-to-blur="false"
                :placeholder="$t('agile.backlog.search.insert')"
                @keypress.enter="addTask"
              />
              <div class="flex-shrink-0 grid grid-cols-2 gap-4 ml-4">
                <base-button
                  icon-size="16"
                  color="text-success"
                  icon="check"
                  @click="addTask"
                />

                <base-button
                  color="text"
                  icon-size="16"
                  class="m-0"
                  icon="close"
                  @click="addTaskToggle"
                />
              </div>
            </div>
            <base-button
              v-else-if="!isUserViewer"
              :ref="visible ? '' : 'addCreateTaskAutoScroll'"
              :data-testid="`create-task-${sprint.name}`"
              full
              border-dashed
              icon="plus"
              icon-size="16"
              color="gray-outline"
              class="m-0"
              wide="py-4"
              @click="addTaskToggle(sprint)"
            >
              <span class="capitalize">
                {{ $t('task.create') }}
              </span>
            </base-button>
          </template>
        </wrapper-observe-visibility>
      </div>
    </div>
  </base-collapse>
</template>

<script>
import VDraggableMixin from '@/mixins/Vuedraggable';
import CollapseToggle from '@/components/collapse/collapse-toggle';
import InfoWorkDuration from '@/components/info/info-work-duration';
import ListTasksDraggable from './list-tasks-draggable.vue';
import ParagraphEditable from '@/components/paragraph-editable';
import WrapperObserveVisibility from '../wrapper/wrapper-observe-visibility.vue';
import ListItemSprintMenu from './list-item-sprint-menu.vue';
import LoadingMixin from '@/mixins/LoadingMixin';
import OpenTask from '@/mixins/OpenTask';
import { mapActions } from 'vuex';

export default {
  mixins: [VDraggableMixin, LoadingMixin, OpenTask],
  components: {
    CollapseToggle,
    InfoWorkDuration,
    ListTasksDraggable,
    ParagraphEditable,
    WrapperObserveVisibility,
    ListItemSprintMenu
  },
  props: {
    sprint: {
      type: Object,
      required: true
    },
    freeze: Boolean
  },
  data() {
    return {
      tasks: [],
      addTaskName: '',
      addTaskActive: false,
      height: 'unset'
    };
  },
  computed: {
    latestTaskUpdated() {
      return this.$store.getters['tasks/getLatestUpdated'];
    },
    taskAddEmit() {
      return {
        name: this.addTaskName,
        sprintID: this.sprint.id
      };
    },
    // modalName() {
    //   return `start-sprint-${this.sprint.id}`;
    // },
    isEmptyTasks() {
      return !this.tasks.length;
    },
    freezeHeight() {
      return this.$refs.freezeHeight;
    },
    isUserViewer() {
      return this.$store.getters['boards/isUserViewer'];
    },
    isUserAdmin() {
      return this.$store.getters['boards/isUserAdmin'];
    }
  },
  mounted() {
    this.onLoading();
    setTimeout(() => this.offLoading(), 500);
  },
  watch: {
    'sprint.tasks': {
      handler(tasks, prev = []) {
        this.setTasks(tasks);
        // if (prev.length !== tasks.length) this.setTasks(tasks);
      },
      immediate: true
    },
    latestTaskUpdated(latest) {
      this.tasks = this.tasks.map(e => (e.id === latest.id ? latest : e));
    },
    freeze(freeze) {
      this.height = freeze ? this.freezeHeight.offsetHeight + 'px' : 'unset';
    }
  },
  methods: {
    ...mapActions('tasks', ['fetchTaskLocal']),
    createSprint() {
      this.$emit('sprint-add');
    },
    toggleStart() {
      this.$modal.toggle('sprint-start');
    },
    splitToArray(routeValue) {
      return routeValue ? routeValue.split(',').map(e => parseInt(e)) : [];
    },
    setTasks(tasks) {
      const { assigneeIDs, tagIDs } = this.$route.query;
      const fetchTasksTags = this.splitToArray(tagIDs);
      const fetchTasksAssign = this.splitToArray(assigneeIDs);
      let ts = tasks;
      if (fetchTasksTags.length > 0) {
        ts = ts.filter(t => {
          if (t.tags) {
            const tags_ids = t.tags.map(e => e.tagID);
            for (let index = 0; index < fetchTasksTags.length; index++) {
              const e = fetchTasksTags[index];
              if (tags_ids.includes(e)) return true;
            }
          }
          return false;
        });
      }
      if (fetchTasksAssign.length > 0 && fetchTasksAssign[0] != -1) {
        ts = ts.filter(t => {
          if (t.assigns) {
            const asss_ids = t.assigns.map(e => e.userID);
            for (let index = 0; index < fetchTasksAssign.length; index++) {
              const e = fetchTasksAssign[index];
              if (asss_ids.includes(e)) return true;
            }
          }
          return false;
        });
      }
      this.tasks = ts.sort(this.sortTask);
    },
    async onCreateTaskButtonClick(collapse) {
      if (collapse.isCollapsed) collapse.toggle(collapse.event);
      if (this.addTaskName) {
        this.addTask();
      } else {
        if (this.addTaskActive == false) {
          await this.addTaskToggle();
        }
        this.autoCreateScroll();
      }
    },
    autoCreateScroll() {
      const elInsert = this.$refs.insertTaskName?.$el;
      elInsert.focus();
      let formatScrollIntoView;
      if ('scrollBehavior' in document.documentElement.style) {
        formatScrollIntoView = { block: 'end' };
      } else {
        formatScrollIntoView = false;
      }
      elInsert.scrollIntoView(formatScrollIntoView);
    },
    addTask() {
      this.$emit('task-add', this.taskAddEmit);
      this.addTaskToggle();
    },
    addTaskToggle() {
      this.addTaskActive = !this.addTaskActive;
      this.addTaskName = '';
    },
    async openTaskDetail(task) {
      this.openTask(task);
      this.$router.push({
        name: 'Board-Backlog-Task',
        params: {
          task_id: task.id.toString()
        },
        query: this.$route.query
      });
    },
    dragChange(evt) {
      if (evt.removed) return;
      const positionItem = this.getDragPosition(this.tasks, evt);
      positionItem.event = evt;
      positionItem.sprintID = this.sprint.id;
      this.$emit('drag-change', positionItem);
    },
    dragstart() {
      this.$emit('drag-task', true);
    },
    dragend() {
      this.$emit('drag-task', false);
    }
  }
};
</script>

<style scopeds>
.sprint__detail {
  @apply text-sm text-gray-caption font-light px-4 cursor-default;
}
.sprint__actions {
  @apply grid place-items-center px-2;
}
.sprint__start-label {
  @apply mb-1 font-medium;
}
</style>
