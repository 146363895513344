var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-collapse',{key:_vm.sprint.id,attrs:{"title":_vm.sprint.name},scopedSlots:_vm._u([{key:"collapser",fn:function(ref){
var isCollapsed = ref.isCollapsed;
var toggle = ref.toggle;
return [_c('collapse-toggle',{staticClass:"hover:bg-light-gray",class:{ 'mb-3': !isCollapsed },attrs:{"active":isCollapsed},on:{"click":toggle}},[_c('div',{staticClass:"flex items-center justify-between flex-wrap"},[_c('div',{staticClass:"font-semibold"},[_c('div',[_vm._v(" "+_vm._s(_vm.sprint.name)+" "),_c('small',{staticClass:"text-gray-caption"},[_vm._v(" "+_vm._s(_vm.$tc('counts.task', _vm.sprint.tasks.length))+" ")])]),_c('div',{staticClass:"flex -mx-2"},[(_vm.sprint.isActive)?_c('info-work-duration',{staticClass:"sprint__detail",attrs:{"start":_vm.sprint.startAt,"end":_vm.sprint.endAt}}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"\n              bg-gray-200\n              rounded-full\n              flex\n              items-center\n              mr-2\n              text-sm\n              px-3\n              py-2\n            "},[_c('base-icon',{attrs:{"icon":"star","size":"14"}}),_c('span',[_vm._v(_vm._s(_vm.$tc('counts.story_point', _vm.$n(_vm.sprint.points))))])],1),(_vm.isUserAdmin && _vm.sprint.allowStart)?_c('div',{staticClass:"sprint__actions"},[_c('base-button',{attrs:{"wide":"board"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleStart.apply(null, arguments)}}},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.$t('sprint.start'))+" ")])])],1):_vm._e(),(_vm.isUserAdmin && _vm.sprint.isBacklog)?_c('div',{staticClass:"sprint__actions"},[_c('base-button',{attrs:{"wide":"board"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('sprint-add', $event)}}},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.$t('sprint.add'))+" ")])])],1):_vm._e(),(!_vm.isUserViewer && !_vm.isEmptyTasks)?_c('div',{staticClass:"sprint__actions"},[_c('base-button',{attrs:{"color":"light-outline","wide":"board","data-testid":("create-task-" + (_vm.sprint.name))},on:{"click":function($event){$event.stopPropagation();return _vm.onCreateTaskButtonClick({
                  toggle: toggle,
                  isCollapsed: isCollapsed,
                  event: $event
                })}}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.$t('task.create')))])])],1):_vm._e(),(_vm.isUserAdmin && !_vm.sprint.isBacklog)?_c('div',{staticClass:"sprint__actions mr-2"},[_c('list-item-sprint-menu',{attrs:{"sprint":_vm.sprint},on:{"delete":function($event){return _vm.$emit('sprint-delete', _vm.sprint.id)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1):_vm._e()])])])]}}])},[_c('div',{ref:"freezeHeight",staticClass:"ml-12",style:({ height: _vm.height })},[(!_vm.loading)?_c('list-tasks-draggable',{attrs:{"freeze":_vm.freeze},on:{"change":_vm.dragChange,"click":_vm.openTaskDetail,"dragstart":_vm.dragstart,"dragend":_vm.dragend},model:{value:(_vm.tasks),callback:function ($$v) {_vm.tasks=$$v},expression:"tasks"}}):_vm._e(),(!_vm.freeze)?_c('div',{staticClass:"pt-4"},[_c('wrapper-observe-visibility',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var visible = ref.visible;
return [(_vm.addTaskActive)?_c('div',{staticClass:"flex"},[_c('paragraph-editable',{ref:"insertTaskName",staticClass:"break-all flex-1",attrs:{"autofocus":"","enter-to-blur":false,"placeholder":_vm.$t('agile.backlog.search.insert')},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTask.apply(null, arguments)}},model:{value:(_vm.addTaskName),callback:function ($$v) {_vm.addTaskName=$$v},expression:"addTaskName"}}),_c('div',{staticClass:"flex-shrink-0 grid grid-cols-2 gap-4 ml-4"},[_c('base-button',{attrs:{"icon-size":"16","color":"text-success","icon":"check"},on:{"click":_vm.addTask}}),_c('base-button',{staticClass:"m-0",attrs:{"color":"text","icon-size":"16","icon":"close"},on:{"click":_vm.addTaskToggle}})],1)],1):(!_vm.isUserViewer)?_c('base-button',{ref:visible ? '' : 'addCreateTaskAutoScroll',staticClass:"m-0",attrs:{"data-testid":("create-task-" + (_vm.sprint.name)),"full":"","border-dashed":"","icon":"plus","icon-size":"16","color":"gray-outline","wide":"py-4"},on:{"click":function($event){return _vm.addTaskToggle(_vm.sprint)}}},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.$t('task.create'))+" ")])]):_vm._e()]}}],null,false,1794918815)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }